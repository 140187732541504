.home-content {
  text-align: center;
  padding: 90px 15px 30px 15px;

  .home-about {
    margin: 0 auto;
    text-align: center;
  }

  section img {
    display: inline-block;
    vertical-align: middle;
    filter: drop-shadow(12px 12px 7px rgba(50, 0, 0, 0.5));
    transition: 0.4s;
  }

  section img:hover {
    filter: drop-shadow(5px 12px 7px rgba(80, 0, 50, 0.5));
  }

  .badge {
    display: inline-block;
    border: 1px solid (100, 0, 50, 0.5);
    border-radius: 2px;
    background: rgba(255, 200, 250, 0.9);
    font-size: 1.7rem;
    padding: 5px 12px;
    color: #201;
    margin: 0 5px 5px 0;
  }

  .button {
    display: inline-block;
    color: #fff;
    font-size: 1.8rem;
    background: #ff1a30;
    border-radius: 50px;
    padding: 10px 30px;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    white-space: nowrap;
    margin: 10px;
  }

  .button:hover {
    color: #fff;
    background: #fe0018;
  }

  .home-logo {
    display: inline-block;
    width: 300px;
    max-width: 80%;
    margin: 1.5rem auto;
  }

  .navigation {
    display: block;
    margin: 15px auto;
    font-size: 3.0rem;

    a { display: inline-block; padding: 10px 15px; }
  }
}
