@import 'base';
@import '~bootstrap/scss/bootstrap.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-image: url('/images/bg.png');
  background-size: 412.5px 412.5px;
  color: #fff;
  font-size: 10px;
  font-family: "Nova Flat", Arial, sans-serif;
  text-align: center;
}

h1, h2, h3, h4 {
  margin: 16px 0;

  .subtitle {
    display: block;
    font-size: 70%;
  }
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.8rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 1.6rem;
}

p, label, input, select, ul, ol {
  font-size: 1.6rem;
}

p { margin: 20px 0; }

.list {
  list-style: circle;
}

input {
  padding: 5px;
  color: $blue;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.2);
}

input[type=text] {
  min-width: 200px;
  max-width: 420px;
  width: 100%;
}
select { border: 2px solid rgba(0,0,0,0.1); }

img { max-width: 100%; max-height: 100%; }

.visible {
  display: block;
}

.hide {
  display: none;
}

footer {
  text-align: center;
  font-size: 2.0rem;
  margin: 15px;
  border-top: 2px solid #ffcc00;
  padding: 30px;
}

a { color: #ffcc00; transition: 0.2s; }
a:hover { color: #ffee00; }
button, .btn { font-size: 1.6rem; padding: 0.8rem 2.4rem; margin: 0 0.8rem; min-width: 140px; }

hr { border-color: $yellow; }

*,
*:before,
*:after {
  box-sizing: inherit;
}

label .copy { min-width: 30%; }

.custom-select {
  font-size: 1.6rem;
  min-width: 20rem;
}
