// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
    100: $gray-100,
    200: $gray-200,
    300: $gray-300,
    400: $gray-400,
    500: $gray-500,
    600: $gray-600,
    700: $gray-700,
    800: $gray-800,
    900: $gray-900
) !default;


$blue:    #007bff !default;
$indigo:  #8f4aff !default;
$purple:  #d34ee3 !default;
$pink:    #e83e8c !default;
$red:     #E50657 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffcc00 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$veryRed: #dd0a22 !default;
$gray: $gray-600;

$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan,
    white: $white,
    gray: $gray-600,
    gray-dark: $gray-800
) !default;

$brand-primary: $yellow;
$brand-secondary: $purple;
$brand-danger: $veryRed;

$theme-colors: (
    primary: $red,
    secondary: $indigo,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $veryRed,
    light: $gray-100,
    dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;
