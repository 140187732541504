.game-card {
  margin: 10px;
  width: 275px;
  height: 380px;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  perspective: 1000px;
  overflow: hidden;

  &.small {
    width: 110px;
    height: 145px;
    min-height: 145px;
    border-radius: 3px;
    .card-sides {
      transform-origin: top left;
      transform: scale(0.40);
    }
  }

  &.small.flipped {
    .card-sides {
      transform: scale(0.40) rotateY(180deg);
    }
  }

  .card-sides {
    transform-style: preserve-3d;
    position: relative;
    transition: 0.5s;
    width: 275px;
    height: 380px;
  }

  .face {
    z-index: 2;
    background: #fffdf4;
    padding: 30px 25px;
    text-align: center;
    text-shadow: 0 0 1px #dbfcff;
    border-radius: 5px;
    min-height: 100%;
    backface-visibility: hidden;
    transform: rotateY(0deg);
    overflow: hidden;
    background-size: cover;
    border-radius: 5px;
    width: 100%;
  }

  &.conspirator, &.dead {
    overflow: visible;

    .card-sides {filter: hue-rotate(-10deg) grayscale(40%);}
    .fire { opacity: 0.9; }

    & img {
      animation: blurPulse 5s ease-in infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(0,0,0, 0.8), rgba(100, 0, 0, 0.2));
    }
  }

  &:hover {
    .face { box-shadow: 0 0 10px rgba(200, 225, 255, 0.5); }
  }

  &.flipped .card-sides{
    transform: rotateY(180deg);
  }

  .card-sides:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.2);
    transform: rotateY(180deg);
    backface-visibility: hidden;
    overflow: hidden;
  }
}

.game-action-card {
  vertical-align: top;
  color: #422554;
  box-shadow: 0 0 20px #000;

  h4 { margin-top: 0; }
  p { text-align: left; font-size: 1.3rem; }
  hr {
    border-color: #422554;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
    box-shadow: 0 0 1px #dbfcff;
  }
  .while { font-size: 1.2rem; }

  .card-sides:after {
    background-image: url(/images/actions/Action-Back.png);
  }
}


$fireColor: rgb(255, 80, 90);
$fireColorT: rgba(255, 70,0,0);
$dur: 1s;
$blur: 0.001em;
$fireRad: 1.4rem;
$parts: 50;
$partSize: 31%;

.fire-starter {
  position: absolute;
  overflow-y: hidden;
  overflow-x: auto;
  bottom: -4px;
  left: -2rem;
  right: -2rem;
  top: 0;
}

.fire {
  overflow-y: hidden;
  filter: blur($blur);
  -webkit-filter: blur($blur);
  margin: 3em auto 0 auto;
  position: relative;
  width: 100%;
  height: 120%;
  position: absolute;
  bottom: -40px;
  z-index: 1;
}
.particle {
  animation: rise $dur ease-in infinite;
  background-image: radial-gradient($fireColor 10%, $fireColorT 80%);
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: $partSize;
  height: $partSize;
  // spread particles out in time and x-position to get desired effect
  @for $p from 1 through $parts {
    &:nth-of-type(#{$p}) {
      animation-delay: $dur * random();
      left: calc((100% - #{$partSize}) * #{($p - 1)/$parts});
      border-radius: random() * 100;
      height: 20 + random() * 20%;
      background-image: radial-gradient(rgb(255,  80 + (60 * random()), 90 + 40 * random()) 5%, $fireColorT 80%);
    }
  }
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(0.9);
  }
  25% {
    opacity: 1;
    color: rgb(255, 20, 90)
  }
  to {
    opacity: 0;
    height: 64%;
    width: 5%;
    transform-origin: center;
    transform: translateY(-9em) scale(0.02);
    color: rgb(255, 20, 200)
  }
}
