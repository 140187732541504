.rules-content {

  padding-top: 8rem;

  nav { position: fixed; top: 6.6rem; }

  .content {
    margin: 30px auto;
    padding: 30px;
    width: 700px;
    max-width: 100%;
    text-align: left;
    background: rgba(0,0,0,0.5);
    border: 1px solid rgba(100,150,200, 0.5);
    border-radius: 3px;
  }
}

.game-info {
  text-align: center;

  h4 {
    display: inline-block;
    padding: 15px;
    text-align: center;
    font-size: 2.2rem;
    min-width: 120px;
  }

  img { max-width: 60px; display: block; margin: 10px auto; }
}


.rules-container {
  .cols li { width: 48%; display: inline-block; min-width: 200px;}

  hr { margin: 30px 0; }

  .characters {
    text-align: center;
  }

  .character {
     display: inline-block;
     margin: 10px;
     width: 150px;

     @media(max-width: 600px) {
       width: 85px;
     }

     img { max-width: 100%; }
     h5 { font-size: 12px; line-height: 17px; }
  }
}

.action-cards {
  text-align: center;
}
