.group-select, .character-select {
  padding: 60px 0;

  i { font-size: 50px; display: block; }
}

.character-select {
  max-width: 900px;
  text-align: center;
  margin: 16px auto;
  padding-top: 60px;
}

.character {
  max-width: 200px;
  text-align: center;
  display: inline-block;
}

.character-avatar {
  position: relative;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;
  display: inline-block;
  background: none;
  border: none;

  img { width: 100%; transition: 0.2s; }

  &:hover img {
    -webkit-filter: drop-shadow(0 0 10px #89c1ff);
    filter: drop-shadow(0 0 10px #89c1ff);
  }

  h4 {
    margin: 0;
  }
}
