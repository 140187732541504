.page-lore {
  padding-top: 8rem;

  nav {
    position: fixed;
    top: 6.6rem;
  }

}

.character-cast {
  max-width: 1200px;
  text-align: center;
  margin: 15px auto;
  padding: 0;

  li {
    max-width: 180px;
    text-align: center;
    display: inline-block;

    img { transition: 0.2s; }

    &:hover img {
      -webkit-filter: drop-shadow(0 0 10px #89c1ff);
      filter: drop-shadow(0 0 10px #89c1ff);
    }

    @media (max-width: 768px) {
      max-width: 120px;
      h4 { font-size: 1.7rem; }
    }
  }
}
