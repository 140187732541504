.notes-content {

  nav {
    position: fixed;
    border-bottom: 2px solid #ffcc00;
    margin-bottom: 12px;
    top: 6.8rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 100;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

  nav a {
    font-size: 2rem;
    display: inline-block;
    line-height: 3rem;
    padding: 0 8px;
    text-align: center;
    font-size: 14px;

    &:hover, &:active, &.active {
      text-decoration: none;
      color: #345;
      text-shadow: 0 0 4px #DEF;
    }

    i { display: block; font-size: 20px; padding-top: 8px; }
  }

  section {
    width: 600px;
    margin: 0 auto;
    max-width: 100%;
  }

  .areYouRasputin {
    position: relative;
    display: block;
    background: #d34ee3;
    color: #fff;
    padding: 15px;
    border: 1px solid #fff;
    box-shadow: inset 0 0 1px #fff;
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 50px;
    padding: 20px 90px;

    .rasputin {
      position: absolute;
      left: -20px;
      bottom: -1px;
      width: 120px;
      height: 120px;
      max-height: 200%;
    }
  }

  padding: 70px 0;

  .bullet-list {
    padding-left: 1rem;
    text-align: left;

    li { display: flex; }
    i { margin: 8px 8px 0 0; }
    p { margin: 0 0 10px; line-height: 20px; }
  }

}

.character-chart {
  margin: 30px auto;
  font-size: 1.8rem;
  text-align: left;
  width: 600px;
  max-width: 100%;
  list-style: none;
  padding: 0;

  li {
    position: relative;
    padding: 15px;
    padding-left: 130px;
    margin: 0 auto;
    background: #111;
    min-height: 110px;
    max-width: 100%;
    border-radius: 4px 3px 3px 3px;
    text-shadow: 0 0 1px rgba(0,0,0,0.2);
    border: 1px solid rgba(255,255,255,0.95);
    box-shadow: 0 0 1px inset rgba(255,255,255,0.5);
    margin-top: 80px;
  }

  @media(max-width: 768px) {
    li {
      margin-top: 24px;
    }
  }

  h4 { display: inline-block; min-width: 180px; margin-top: 0; }

  .who { display: inline-block; margin-top: 0; vertical-align: middle; width: 180px; margin-top: 5px; }
  .index { position: absolute; top: 10px; right: 10px; }

  .image {
    position: absolute;
    width: 160px;
    bottom: -2px;
    left: -30px;
    max-height: 200%;
    z-index: 1;
  }

  input { padding: 5px; min-width: 180px; width: 180px; max-width: 100%;  }
  input:focus { outline-offset: 2px; outline: 1px solid #fff; }

  li.conspirator { background: #ff3333; }
  li.royal { background: #e4b600; }

  .faction {
    width: 32px;
    height: 32px;
    padding: 4px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    top: -5px;
    left: -5px;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    img { max-width: 100%; margin-bottom: 5px; }
    i { position: relative; color: red; right: -2px; top: -1px; }
  }
}

.weapon-chart {
  margin: 30px auto;
  font-size: 1.8rem;
  text-align: left;
  max-width: 500px;
  padding: 0;

  list-style: none;

  .rasputin { background: #ffcc00; text-align: center; text-shadow: 0 1px 1px rgba(0,0,0,0.1); }
  .owner { display: inline-block; width: 100px; float: right; position: relative; top: -2px; }

  @media (max-width: 600px) {
    .owner { float: right; }
  }

  input[type=checkbox] {
     width: 18px;
     height: 18px;
     margin-right: 5px;
  }

  li { padding: 15px; }

  li:nth-child(even) {
    background: #444;
  }
}

.weapons-list {
  width: 1200px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 0;

  .weapon {
    position: relative;
    display: inline-block;
    width: 180px;
    padding: 10px;
    margin: 10px;
    min-height: 220px;
    background: #fffdf4;
    border-radius: 5px;
    color: #422554;
    text-shadow: 0 0 1px #fff;

    h4 { margin: 5px; }
    img { transition: 0.2s;  }
  }

  @media (max-width: 768px) {
    .weapon { width: 170px; }
  }

  input:checked + img {
    filter: grayscale(90%) blur(1px);
    opacity: 0.9;
  }

  input[type=checkbox] {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  input[type=text] {
    width: 100%;
    min-width: 100%;
  }
}

.turn-order {
  max-width: 420px;
  margin: 15px auto;
  text-align: left;
  font-size: 1.6rem;
  padding: 30px;
  border-bottom: 2px solid #ffcc00;
}

.actions {
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
  padding: -10px;
}
