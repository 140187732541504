.character-info {
  max-width: 1200px;
  text-align: center;
  margin: 15px auto;
  vertical-align: top;

  img {
    max-width: 300px;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 3rem;
  }
}

.character-meta {
  width: 400px;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  padding: 20px;
}

