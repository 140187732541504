.page-layout {
  padding-top: 5.0rem;
}

.header {
  background-image: url('/images/bg.png');
  padding: 0 12px;
  border-bottom: 2px solid #ffcc00;
  font-size: 1.8rem;
  vertical-align: middle;
  text-align: left;
  line-height: 6.6rem;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;

  a {
    padding: 0 12px;
    vertical-align: middle;
    display: inline-block;
  }

  a:hover {
    text-decoration: none;
  }

  a.logo {
    padding: 0 8px;
  }

  @media(min-height: 320px) {
    position: fixed;
    z-index: 100;
  }
}

.header-active {
  text-decoration: none;
  text-shadow: 0 0 3px #000;
  color: #fff;
  border-bottom: 2px solid #67acff;
}

nav {
  background: #ffcc00;
  padding: 1px;
  color: #112;
  left: 0;
  right: 0;

  a {
    color: #112;
    line-height: 3rem;
  }

  a:hover {
    color: #000;
  }
}

.logo {
  float: left;
  height: 6.6rem;
}

.page-content {
  min-height: 60vh;

  .form-group {
    max-width: 900px;
    margin: auto;
  }
}

.content {
  padding: 15px;
  min-height: 50vh;
}

footer {
  border-top: none;
  margin: 0;

  .logo {
    display: block;
    width: 100px;
    margin: 15px auto;
  }

  a {
    padding: 15px;
  }
}

.hero-digest {
  max-width: 100%;
  padding: 60px 0;
  text-align: center;

  img { display: inline-block; vertical-align: top; }

  .meta {
    text-align: left;
    display: inline-block;
    width: 600px;
    max-width: 100%;
    padding: 15px;
    vertical-align: middle;

    p { font-size: 1.9rem; line-height: 2.8rem; }
  }

  a { font-size: 2rem; }
}
